/* IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* VARS */
:root {
	--font-stack: 'Poppins', sans-serif;
	--font-stack-2: 'Inter', sans-serif;
	--font-stack-3: 'Jost', sans-serif;
	--font-stack-4: 'DM Sans', sans-serif;
	--font-stack-5: 'Montserrat', sans-serif;
	--blue: #50a7ef;
	--blue-2: #3b5998;
	--blue-3: #50a7ef;
	--blue-4: #51a7ef;
	--blue-5: #f5fbff;
	--blue-6: #3b80b9;
	--light-blue-1: #f6fbfe;
	--light-blue-2: #cbe5fa;
	--light-blue-3: #b2d5f1;
	--light-blue-4: #ecf6fd;
	--dark-blue-1: #454f5b;
	--dark-blue-2: #212b36;
	--dark-blue-3: #072f54;
	--dark-blue-4: #062554;
	--red-1: #d64646;
	--grey-1: #f4f4f4;
	--grey-2: #fdfdfd;
	--grey-3: #9fa3a8;
	--grey-4: #dfdfdf;
	--grey-5: #696974;
	--grey-6: #c4c4c4;
	--grey-7: #393a3b;
	--grey-8: #f2f2f2;
	--grey-9: #979797;
	--grey-10: #f1f1f5;
	--grey-11: #8f969b;
	--grey-12: #f9f9f9;
	--grey-13: #9fa3a8;
	--grey-14: #ededf0;
	--grey-15: #4a4b57;
	--grey-16: #f1f1f1;
	--grey-17: #f7f7fa;
	--grey-18: #f5f3f3;
	--grey-19: #f9fafb;
	--grey-20: #6f6c90;
	--grey-21: #d9dbe9;
	--white: #ffffff;
	--white-2: #fefefe;
	--black: #000000;
	--black-2: #212b36;
	--fire-color: #fdddd7;
	--box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* RESET */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: var(--font-stack);
	/* border: 0.1em solid red; */
}

html,
body {
	padding: 0;
	margin: 0;
	overflow-x: hidden !important;
}

body {
	min-height: 100vh;
	background: var(--white-2);
	transition: color 0.5s, background-color 0.5s;
}

/* GLOBALS */
p {
	font-size: 1em;
}

/* ALICE CAROUSEL DOTS FIX */
.alice-carousel__dots {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.alice-carousel__dots-item:not(.__custom).__active {
	background-color: var(--blue);
}

.App {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.fade-appear,
.fade-enter {
	opacity: 0;
	z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 450ms linear 250ms;
}
.fade-exit {
	opacity: 1;
}
.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 150ms linear;
}

.page-enter {
	opacity: 0;
	transform: scale(1.1);
}

.page-enter-active {
	opacity: 1;
	transform: scale(1);
	transition: opacity 300ms, transform 300ms;
}

.page-exit {
	opacity: 1;
	transform: scale(1);
}

.page-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

.ant-modal-content {
	border-radius: 0.5em !important;
}

.action-button {
	color: white;
	padding: 0.8em;
	width: 200px;
	cursor: pointer;
	border: 1px solid var(--blue-3);
	border-radius: 0.5em;
	transition: 0.3s ease;
	background: var(--blue-3);
	font-weight: 600;
}
.action-button:hover {
	background: none;
	color: var(--blue-3);
}

@media screen and (max-width: 500px) {
	.action-button {
		margin: 0.5em 0;
		font-size: 0.9em;
	}
}
